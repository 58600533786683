<template>
  <v-dialog v-model="dialogChangeValue" persistent width="500">
    <v-card class="d-flex flex-column">
      <v-card-title style="width: 100%" class="ma-0 pa-0">
        <v-toolbar width="100%" height="50" :color="$colors[0]" class="text-white" dark>
          <span>Changement {{ typeDate }} - {{ itemTable.fournisseur }} </span>
          <v-icon style="position: absolute; right: 10px" @click="closeDialog()"
            >mdi-close</v-icon
          >
        </v-toolbar>
      </v-card-title>
      <div class="pa-4 mt-4">
        <v-form ref="changeScore">
          <span style="font-size: 12px; font-weight: 500" :style="{ color: $colors[0] }"
            >Valeur {{ typeDate }}</span
          >
          <v-text-field
            class="mt-1"
            outlined
            :rules="[
              $rules.required,
              $rules.ruleValue(typeDate, itemTable.assureur, modelValue),
            ]"
            dense
            :type="typeDate == 'score' ? 'number' : 'text'"
            v-model="modelValue"
            :placeholder="'Valeur ' + typeDate"
          >
          </v-text-field>

          <v-btn
            @click="changeValue()"
            width="100%"
            class="white--text"
            :color="$colors[0]"
            >Valider</v-btn
          >
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DialogChangeValue",
  props: ["dialogChangeValue", "itemTable", "typeDate", "keyDate"],
  data() {
    return {
      modelValue: this.itemTable[this.keyDate],
      keyModel: this.keyDate,
    };
  },
  methods: {
    changeValue() {
      if (this.$refs.changeScore.validate()) {
        this.$emit("changeValue", this.modelValue);
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>
