import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/services/api'
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    token: null,
  },
  mutations: {
    SET_USER(state, user) {
      const parsedUser = JSON.stringify(user);
      localStorage.setItem('user', parsedUser);
      state.user = user;
    },
    SET_TOKEN(state, token) {
      localStorage.setItem('token', token);
      state.token = token;
    },
  },
  actions: {
    initializeStore({ commit }) {
      const token = localStorage.getItem('token');
      const user = localStorage.getItem('user');
      const parsedUser = user ? JSON.parse(user) : null;

      if (token && user) {
        commit('SET_USER', parsedUser);
        commit('SET_TOKEN', token);
      }
    },
    async login({ commit }, credentials) {
      try {
        const response = await api.post('login/', credentials);
        if (response.status == 200 && response.data.login == true) {
          const user = {
            nom: response.data.nom,
            prenom: response.data.prenom,
            email: response.data.email,
            role: response.data.type,
            phone: response.data.Telephone,
          };
          commit('SET_USER', user);
          commit('SET_TOKEN', response.data.token);
          router.push('/formulaire-csv');
        }
      } catch (error) {
        // Handle authentication error.
        throw error;
      }
    },
    async logout({ commit }) {
      try {
        // Clear the user information and token from both Vuex and localStorage
        commit('SET_USER', null);
        commit('SET_TOKEN', null);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
  
        // Optionally, you can also redirect the user to the login page after logout
        router.push('/login');
      } catch (error) {
        // Handle logout error.
        throw error;
      }
    },
  },
  getters: {
    isAuthenticated: (state) => {
      return state.token ? true : false;
    },
    getUser: (state) => {
      return state.user;
    },
    getToken: (state) => {
      return state.token;
    },
  },
  modules: {
  }
})
