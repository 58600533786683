<template>
  <v-dialog v-model="dialog" width="700">
    <v-card>
      <v-card-title style="width: 100%" class="ma-0 pa-0">
        <v-toolbar width="100%" height="50" :color="$colors[0]" class="text-white" dark>
          <span>Ajouter une Assurance </span>
          <v-icon style="position: absolute; right: 10px" @click="closeDialog()"
            >mdi-close</v-icon
          >
        </v-toolbar>
      </v-card-title>
      <v-form ref="addFournisseurAssurance" class="pa-4">
        <div>
          <span style="font-size: 12px; font-weight: 500" :style="{ color: $colors[0] }"
            >Liste de fournisseurs</span
          >
          <v-select
            class="mt-2"
            placeholder="Liste de fournisseurs"
            outlined
            dense
            :rules="[$rules.required]"
            v-model="assurance.fournisseur"
            :items="listFournisseurs"
          >
          </v-select>
        </div>
        <div class="d-flex flex-row">
          <div style="width: 50%" class="d-flex flex-column">
            <span
              class="mb-2"
              style="font-size: 12px; font-weight: 500"
              :style="{ color: $colors[0] }"
              >Date Debut Min</span
            >
            <v-text-field
              style="width: 100%"
              type="date"
              outlined
              dense
              :rules="[
                $rules.isDateValid,
                $rules.required,
                $rules.compareDate(assurance.date_debut_min, assurance.date_debut_max),
              ]"
              v-model="assurance.date_debut_min"
            >
            </v-text-field>
          </div>
          <div style="width: 50%" class="d-flex flex-column ml-2">
            <span
              class="mb-2"
              style="font-size: 12px; font-weight: 500"
              :style="{ color: $colors[0] }"
              >Date Debut Max</span
            >
            <v-text-field
              style="width: 100%"
              class=""
              outlined
              type="date"
              dense
              :rules="[
                $rules.isDateValid,
                $rules.required,
                $rules.compareDate(assurance.date_debut_min, assurance.date_debut_max),
              ]"
              v-model="assurance.date_debut_max"
            >
            </v-text-field>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div style="width: 50%" class="d-flex flex-column">
            <span
              class="mb-2"
              style="font-size: 12px; font-weight: 500"
              :style="{ color: $colors[0] }"
              >Date Fin Min</span
            >
            <v-text-field
              style="width: 100%"
              type="date"
              outlined
              dense
              :rules="[
                $rules.required,
                $rules.isDateValid,
                $rules.compareDate(assurance.date_fin_min, assurance.date_fin_max),
              ]"
              v-model="assurance.date_fin_min"
            >
            </v-text-field>
          </div>
          <div style="width: 50%" class="d-flex flex-column ml-2">
            <span
              class="mb-2"
              style="font-size: 12px; font-weight: 500"
              :style="{ color: $colors[0] }"
              >Date Fin Max</span
            >
            <v-text-field
              style="width: 100%"
              outlined
              type="date"
              dense
              :rules="[
                $rules.required,
                $rules.isDateValid,
                $rules.compareDate(assurance.date_fin_min, assurance.date_fin_max),
              ]"
              v-model="assurance.date_fin_max"
            >
            </v-text-field>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div style="width: 50%" class="d-flex flex-column">
            <span style="font-size: 12px; font-weight: 500" :style="{ color: $colors[0] }"
              >Liste d'assureurs</span
            >
            <v-select
              class="mt-2"
              v-model="assurance.assureur"
              outlined
              dense
              :rules="[$rules.required]"
              placeholder="Liste assureurs"
              :items="listAssureurs"
            >
            </v-select>
          </div>
          <div style="width: 50%" class="d-flex flex-column ml-2">
            <span style="font-size: 12px; font-weight: 500" :style="{ color: $colors[0] }"
              >Score</span
            >
            <v-text-field
              class="mt-2"
              type="number"
              placeholder="Score"
              :rules="[
                $rules.required,
                $rules.isNumber,
                $rules.ruleValue('score', assurance.assureur, assurance.score),
              ]"
              v-model="assurance.score"
              outlined
              dense
            >
            </v-text-field>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div style="width: 50%" class="d-flex flex-column">
            <span style="font-size: 12px; font-weight: 500" :style="{ color: $colors[0] }"
              >Code NAF</span
            >
            <v-text-field
              class="mt-2"
              placeholder="1111A"
              :rules="[
                $rules.required,
                $rules.ruleValue('code NAF', '', assurance.codeNaf),
              ]"
              v-model="assurance.codeNaf"
              outlined
              dense
            >
            </v-text-field>
          </div>
          <div style="width: 50%" class="d-flex flex-column ml-2">
            <span style="font-size: 12px; font-weight: 500" :style="{ color: $colors[0] }"
              >Conso ETE</span
            >
            <v-text-field
              placeholder="0"
              class="mt-2"
              :rules="[
                $rules.required,
                $rules.isNumber,
                $rules.ruleValue('Conso ETE', '', assurance.consoEte),
              ]"
              type="number"
              v-model="assurance.consoEte"
              outlined
              dense
            >
            </v-text-field>
          </div>
        </div>

        <div class="d-flex flex-row justify-end">
          <v-btn @click="ajouter()" :color="$colors[0]" class="white--text"
            >Ajouter</v-btn
          >
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DialogAddFournisseurAssurance",
  props: ["dialog"],
  data() {
    return {
      listFournisseurs: ["PRIMEO", "ENOVOS", "EDS"],
      listAssureurs: ["CREDITSAFE", "ELLIPRO"],
      assurance: {
        fournisseur: "",
        date_debut_min: this.$dayjs(new Date()).format("YYYY-MM-DD"),
        date_debut_max: this.$dayjs(new Date()).format("YYYY-MM-DD"),
        date_fin_min: this.$dayjs(new Date()).format("YYYY-MM-DD"),
        date_fin_max: this.$dayjs(new Date()).format("YYYY-MM-DD"),
        assureur: "",
        score: 0,
        codeNaf: "",
        consoEte: "",
      },
    };
  },

  methods: {
    ajouter() {
      console.log(this.assurance);
      if (this.$refs.addFournisseurAssurance.validate()) {
        this.$emit("addAssurance", this.assurance);
        this.assurance = {};
        this.$refs.addFournisseurAssurance.resetValidation();
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
      this.$refs.addFournisseurAssurance.resetValidation();
    },
  },
};
</script>
