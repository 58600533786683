import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import AppLayout from '@/layouts/AppLayout.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import FournisseurAssurance from "@/views/FournisseurAssurance.vue"
import FormulaireCSV from "@/views/FormulaireCSV.vue"
import FormulaireFichier from "@/views/formulaire-fichier/FormulaireFichier.vue"
import FormulaireTaxe from "@/views/taxe/FormulaireTaxe.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: AuthLayout,
    children: [
      {
        path: '',
        name: 'home',
        redirect: '/login',
        meta: { 
          requiresAuth: false,
          hidden: true,
          },

      }
    ]
  },
  {
    path: '/login',
    component: AuthLayout,
    children: [
      {
        path: '',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/login/LoginView.vue'),
      }
    ]
  },
 
  {
    path: '/turpe',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'turpe',
        meta: { requiresAuth: true,roles: ["admin", "superadmin"], icon: "mdi-gesture-tap-box"},
        component: () => import(/* webpackChunkName: "about" */ '@/views/turpe/FournisseurTurpe.vue'),
      }
    ]
  },
  {
    path: '/formulaire-assureur',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'les Coefficients Gaz',
        meta: { requiresAuth: true,roles: ["admin", "superadmin"], icon: "mdi-gesture-tap-box"},
        component: () => import(/* webpackChunkName: "formulaire-assureur" */ '@/views/assureur/FormulaireAssureur.vue'),
      }
    ]
  },
  {
    path: '/assurance',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'Assurance',
        meta: { requiresAuth: true,roles: ["admin", "superadmin"], icon:"mdi-file-excel-box"},
        component: FournisseurAssurance
      }
    ]
  },
  {
    path: '/formulaire-csv',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'Mise à jour des prix',
        meta: { requiresAuth: true,roles: ["admin", "superadmin"], icon:"mdi-file-excel-box"},
        component: FormulaireCSV
      }
    ]
  },
  {
    path: '/formulaire-fichier',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'Document VentePro',
        meta: { requiresAuth: true,roles: ["admin", "superadmin"], icon:"mdi-file-excel-box"},
        component: FormulaireFichier
      }
    ]
  },
  {
    path: '/formulaire-taxe',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'ATRDATRT GAZ',
        meta: { requiresAuth: true,roles: ["admin", "superadmin"], icon:"mdi-file-excel-box"},
        component: FormulaireTaxe
      }
    ]
  }
  
]



const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  // get routes that require authentication
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  // check user authenticated or not
  const isAuthenticated = store.getters.isAuthenticated;


  if (isAuthenticated) {
    // If the user is authenticated and tries to access the login page,
    // redirect to the home page.
    if (to.path === '/login' || to.path === '/') {
      next('/formulaire-csv');
    } else {
      next();
    }
  } else if (requiresAuth && !isAuthenticated) {
    // If the route requires authentication and the user is not authenticated,
    // redirect to the login page.
    next('/login');

  } else {
    // For all other cases, proceed to the next route.
    next();
  }
});

export default router;

