<template>
  <v-app>
    <Drawer />
    <v-main class="bg">
      <router-view/>
    </v-main>
  </v-app>
</template>
<script>
import Drawer from "@/components/drawer/Drawer";
export default {
  name: "AppLayout",
  components: {
    Drawer
  }
}
</script>
<style>
.bg{
  background-color: var(--main-bg-color);
}
</style>