import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import dayjs from "dayjs"
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";


Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false
Vue.$router = router;
Vue.prototype.$store = store;
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$colors = ["#01579B", "#1E6CA4","#FBC02D","#fab627"];
Vue.prototype.$rules = {
  compareDate(dateDebut,dateFin){
    console.log(dateDebut,dateFin)
    if(dateFin > dateDebut){
      return true
    }else {
      return "La date de fin doit etre supérieure à la date de début"
    }
  },
  formatMPlus:v=> (/^M\+\d+$/).test(v) || 'Format incorrect',
  isDateValid:v=>(/^\d{4}-\d{2}-\d{2}$/).test(v) || v == "NA" ||(/^M\+\d+$/).test(v) || "Cette date est incorrect !",
  isNumber: v => (/^[0-9]+$/).test(v) || "Ce champ n'accepte que les chiffres/nombres positifs",
  matchFormat:v => (/^M\+\d+$/).test(v) || 'Format incorrect',
  ruleValue(type,model,v){
    if(type == 'score' || type == "assureur"){
      if(model === 'ELLIPRO'){
        return v >= 0 && v <= 15 || "valeur invalide"
      }else if(model === 'CREDITSAFE'){
        return v >= 0 && v <= 100 || "valeur invalide"
      }
    }else if (type == 'code NAF') {
      if (/^\d{4}[A-Za-z]$/.test(v)) {
        return true;
      } else {
        return "Code NAF incorrect";
      }
    }
    else if(type == 'Conso ETE'){
      return !!v || v == 0 || 'valeur invalide'
    }
  },
  emailRules:v => (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(v) || 'E-mail invalide', 
  matchingPassword(nouveauMDP,confirmationMDP) {
    return nouveauMDP === confirmationMDP || "mot de passe différent !"
  },
  required(value){
    return !!value || 'Champ obligatoire !'
  },
  requiredtable(v){
    return v.lenght > 0 || "Champ obligatoire !"
  }
}

store.dispatch('initializeStore').then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
});
