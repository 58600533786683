<template>
  <div class="mx-4">
    <DialogAddFournisseurAssurance
      :dialog="dialogAddFournisseurAssurance"
      @addAssurance="addAssurance($event)"
      @closeDialog="closeModalAddFournisseurAssurance"
    />
    <div class="ma-4 ml-0">
      <v-btn
        :color="$colors[0]"
        class="white--text"
        @click="openModalAddFournisseurAssurance"
      >
        Ajouter une assurance
      </v-btn>
    </div>
    <v-form ref="formDate">
      <CustomDatatable
        ref="customDatatable"
        :columns="columns"
        :isPaginated="true"
        :paginationObjectProps="pagination"
        :isLoading="isLoading"
        :haveNoData="listeFournisseursAssurance?.length == 0 && isLoading == false"
        sortKey=""
        sortOrders=""
        @sort=""
        @refreshData="getData"
        @search="searchData"
      >
        <template v-slot:body>
          <tbody>
            <tr class="text-center" v-for="(item, index) in listeFournisseursAssurance">
              <td>{{ item.fournisseur }}</td>
              <!--<td>
              <v-btn
                width="90%"
                dense
                text
                @click="openDialogDate(item, 'date', 'date_debut_min')"
                >{{ item.date_debut_min }}</v-btn
              >
            </td>
            <td>
              <v-btn
                width="90%"
                dense
                text
                @click="openDialogDate(item, 'date', 'date_debut_max')"
                >{{ item.date_debut_max }}</v-btn
              >
            </td>
            <td>
              <v-btn
                width="90%"
                dense
                text
                @click="openDialogDate(item, 'date', 'date_fin_min')"
                >{{ item.date_fin_min }}</v-btn
              >
            </td>
            <td>
              <v-btn
                width="90%"
                dense
                text
                @click="openDialogDate(item, 'date', 'date_fin_max')"
                >{{ item.date_fin_max }}</v-btn
              >
            </td>
            <td>
              <v-btn
                width="90%"
                dense
                text
                @click="openDialogAssureur(item, 'assureur', '')"
                >{{ item.assureur }}</v-btn
              >
            </td>
            <td>
              <v-btn
                width="90%"
                dense
                text
                @click="openDialogChangeValue(item, 'score', 'score')"
                >{{ item.score }}</v-btn
              >
            </td>
            <td>
              <v-btn
                width="90%"
                dense
                text
                @click="openDialogChangeValue(item, 'code NAF', 'codeNaf')"
                >{{ item.codeNaf }}</v-btn
              >
            </td>
            <td>
              <v-btn
                width="90%"
                dense
                text
                @click="openDialogChangeValue(item, 'consoEte', 'consoEte')"
                >{{ item.consoEte }}</v-btn
              >
            </td>-->
              <td v-for="(keyDate, indexDate) in listeDate" :key="indexDate">
                <span v-if="!isChangeRow || changeRowIndex != index">{{
                  item[keyDate]
                }}</span>
                <div v-if="changeRowIndex == index">
                  <div>
                    <!--<v-text-field
                      :rules="[$rules.isDateValid]"
                      dense
                      outlined
                      v-model="item[keyDate]"
                    ></v-text-field>-->
                    <v-text-field
                      v-if="
                        optionDateModel[indexDate] == 'Calendrier' ||
                        ($dayjs(item[keyDate], 'YYYY-MM-DD', true).isValid() &&
                          !item[keyDate].startsWith('M+'))
                      "
                      dense
                      outlined
                      v-model="item[keyDate]"
                      type="date"
                    ></v-text-field>

                    <v-text-field
                      v-if="
                        optionDateModel[indexDate] == 'M+' ||
                        item[keyDate].startsWith('M+')
                      "
                      :rules="[$rules.formatMPlus, $rules.required]"
                      dense
                      outlined
                      v-model="item[keyDate]"
                    ></v-text-field>
                    <v-text-field
                      v-if="optionDateModel[indexDate] == 'NA' || item[keyDate] == 'NA'"
                      dense
                      outlined
                      disabled
                      v-model="item[keyDate]"
                      value="NA"
                    ></v-text-field>
                  </div>
                  <div class="d-flex flex-row mb-4">
                    <v-btn
                      @click="changeValueDate(index, indexDate, keyDate, 'NA')"
                      small
                      class="mx-1"
                      >NA</v-btn
                    >
                    <v-btn
                      @click="changeValueDate(index, indexDate, keyDate, 'M+')"
                      small
                      class="mx-1"
                      >M+</v-btn
                    >
                    <v-btn
                      @click="changeValueDate(index, indexDate, keyDate, 'Calendrier')"
                      small
                      class="mx-1"
                      ><v-icon small>mdi-calendar-range</v-icon></v-btn
                    >
                  </div>
                </div>
              </td>

              <td>
                <span v-if="!isChangeRow || changeRowIndex != index">{{
                  item.assureur
                }}</span>
                <div v-if="changeRowIndex == index">
                  <v-select
                    dense
                    outlined
                    v-model="item.assureur"
                    :items="['CREDITSAFE', 'ELLIPRO']"
                  ></v-select>
                </div>
              </td>
              <td>
                <span v-if="!isChangeRow || changeRowIndex != index">{{
                  item.score
                }}</span>
                <v-text-field
                  v-if="changeRowIndex == index"
                  outlined
                  dense
                  :rules="[$rules.ruleValue('score', item.assureur, item.score)]"
                  v-model="item.score"
                ></v-text-field>
              </td>
              <td :style="{ 'max-width': '100px', 'word-wrap': 'break-word' }">
                <span v-if="!isChangeRow || changeRowIndex != index">
                  <!-- if length > 50 add a tooltip -->
                  {{ item.codeNaf ? item.codeNaf.length > 50 ? item.codeNaf.substring(0, 50) + '...' : item.codeNaf : '' }}
                </span>
                
                <v-text-field
                  v-if="changeRowIndex == index"
                  outlined
                  dense
                  :rules="[$rules.ruleValue('code NAF', '', item.codeNaf)]"
                  v-model="item.codeNaf"
                ></v-text-field>
              </td>
              <td>
                <span v-if="!isChangeRow || changeRowIndex != index">{{
                  item.consoEte
                }}</span>
                <v-text-field
                  v-if="changeRowIndex == index"
                  outlined
                  dense
                  :rules="[$rules.ruleValue('Conso ETE', '', item.consoEte)]"
                  v-model="item.consoEte"
                ></v-text-field>
              </td>
              <td>
                <div>
                  <v-btn
                    v-if="!isChangeRow || changeRowIndex != index"
                    @click="modifyRow(index)"
                    :color="$colors[0]"
                    outlined
                  >
                    <v-icon dense>mdi-pencil-outline</v-icon>
                  </v-btn>
                  <div class="d-flex flex-row">
                    <v-btn
                      v-if="isChangeRow && changeRowIndex == index"
                      @click="confirmModification(item, index)"
                      color="gray"
                      outlined
                    >
                      <v-icon dense>mdi-pencil-off-outline</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="isChangeRow && changeRowIndex == index"
                      class="ml-4"
                      @click="confirmModification(item, index)"
                      color="green"
                      outlined
                    >
                      <v-icon dense>mdi-check-all</v-icon>
                    </v-btn>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <div>
            <DialogDate
              v-if="dialogDate"
              :dialogDate="dialogDate"
              :itemTable="itemTable"
              :typeDate="typeDate"
              :keyDate="keyDate"
              @changeDate="changeDate($event)"
              @closeDialog="dialogDate = false"
            />
            <DialogAssureur
              v-if="dialogAssureur"
              :dialogAssureur="dialogAssureur"
              :itemTable="itemTable"
              :typeDate="typeDate"
              :keyDate="keyDate"
              @changeAssureurAndScore="changeValueAssureurAndScore($event)"
              @closeDialog="dialogAssureur = false"
            />
            <DialogChangeValue
              v-if="dialogChangeValue"
              :dialogChangeValue="dialogChangeValue"
              :itemTable="itemTable"
              :typeDate="typeDate"
              :keyDate="keyDate"
              @changeValue="changeValue($event)"
              @closeDialog="dialogChangeValue = false"
            />
          </div>
        </template>
      </CustomDatatable>
    </v-form>
  </div>
</template>
<script>
import DialogAddFournisseurAssurance from "@/components/fournisseurAssurance/DialogAddFournisseurAssurance.vue";
import DialogAssureur from "@/components/fournisseurAssurance/DialogAssureur.vue";
import DialogDate from "@/components/fournisseurAssurance/DialogDate.vue";
import DialogChangeValue from "@/components/fournisseurAssurance/DialogChangeValue.vue";
import CustomDatatable from "@/components/datatable/CustomDatatable.vue";
import api from "@/services/api";
export default {
  name: "FournisseurAssurance",
  components: {
    CustomDatatable,
    DialogDate,
    DialogAssureur,
    DialogChangeValue,
    DialogAddFournisseurAssurance,
  },
  data() {
    return {
      isLoading: false,
      tableData: {
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
        filterItems: null,
        filterType: "all",
      },
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 1,
        lastPage: 1,
        currentPage: 1,
        total: 0,
        lastPageUrl: null,
        nextPageUrl: null,
        prevPageUrl: null,
        from: 0,
        to: 0,
      },
      optionDateModel: ["", "", "", ""],
      listeOptionDate: ["NA", "M+", "Calendrier"],
      listeDate: ["date_debut_min", "date_debut_max", "date_fin_min", "date_fin_max"],
      changeRowIndex: null,
      isChangeRow: false,
      dialogAddFournisseurAssurance: false,
      dialogDate: false,
      dialogAssureur: false,
      dialogChangeValue: false,
      itemTable: {},
      itemValue: "",

      keyDate: "",
      keyDateModification: "",
      typeDate: "",
      columns: [
        { width: "12vw", label: "Fournisseur", name: "fournisseur", sortable: false }, // Les sortables sont tous mis en false par défaut a changer plus tard si besoin
        {
          width: "12vw",
          label: "Date Debut Min",
          name: "date_debut_min",
          sortable: false,
        },
        {
          width: "12vw",
          label: "Date Debut Max",
          name: "date_debut_max",
          sortable: false,
        },
        { width: "12vw", label: "Date Fin Min", name: "date_fin_min", sortable: false },
        { width: "12vw", label: "Date Fin Max", name: "date_fin_max", sortable: false },
        { width: "12vw", label: "Assureur", name: "assureur", sortable: false },
        {
          width: "12vw",
          label: "Score",
          name: "score",
          sortable: false,
        },
        { width: "12vw", label: "Code NAF", name: "code_naf", sortable: false },
        { width: "12vw", label: "Conso ETE", name: "conso_ete", sortable: false },
        { width: "12vw", label: "Actions", name: "actions", sortable: false },
      ],
      listeFournisseursAssurance: [],
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    // get Data
    searchData(searchWord) {
      this.tableData.search = searchWord;
      this.getData();
    },
    async getData(pageNumber = 1) {
      this.isLoading = true;
      try {
        const token = this.$store.getters.getToken || null;
        const response = await api.post('getAllAssureurDatatable/', {
          dataTable: this.tableData,
          "pageNumber": pageNumber,
          "token": token
        });
        if (response.data.result == true) {
          let data = response.data.data;
          this.listeFournisseursAssurance = data.data.data;
          if (this.tableData.draw == data.draw) {
            this.configPagination(data.data);
          }
        }
      } catch (error) {
        console.log(error)
      }
      this.isLoading = false;
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },


    // modify Assurance
    changeValueDate(index, indexListeOption, key, newValue) {
      this.optionDateModel.splice(indexListeOption, 1, newValue);
      if (newValue == "Calendrier") {
        this.listeFournisseursAssurance[index][key] = this.$dayjs(new Date()).format(
          "YYYY-MM-DD"
        );
      } else if (newValue == "M+") {
        this.listeFournisseursAssurance[index][key] = "M+0";
      } else {
        this.listeFournisseursAssurance[index][key] = newValue;
      }
    },
    confirmModification(item, index) {
      console.log(item);
      if (this.$refs.formDate.validate()) {
        this.optionDateModel.forEach((element, index) => {});
        this.changeRowIndex = null;
        this.isChangeRow = false;
        var id = item.id;
        var date_debut_min = item.date_debut_min;
        var date_debut_max = item.date_debut_max;
        var date_fin_min = item.date_fin_min;
        var date_fin_max = item.date_fin_max;
        var assureur = item.assureur;
        var score = item.score;
        var codeNaf = item.codeNaf;
        var consoEte = item.consoEte;
        const token = this.$store.getters.getToken || null;
        const formData = new FormData();
        formData.append("id", id);
        formData.append("date_debut_min", date_debut_min);
        formData.append("date_debut_max", date_debut_max);
        formData.append("date_fin_min", date_fin_min);
        formData.append("date_fin_max", date_fin_max);
        formData.append("assureur", assureur);
        formData.append("score", score);
        formData.append("codeNaf", codeNaf);
        formData.append("token", token);
        formData.append("consoEte", consoEte);
        api.post("updateAssureur/", formData).then((response) => {
          if (response.data.result == true) {
            alert("item updated successfully");
            
          } else {
            alert("item updated failed");
          }
        });
        
       
      }
    },
    modifyRow(index) {
      this.changeRowIndex = index;
      this.isChangeRow = true;
    },
    openDialogDate(item, typeDate, keyDate) {
      this.itemTable = item;
      this.typeDate = typeDate;
      this.keyDate = keyDate;

      this.dialogDate = true;
    },
    openDialogAssureur(item, typeDate, keyDate) {
      this.itemTable = item;
      this.typeDate = typeDate;
      this.keyDate = keyDate;

      this.dialogAssureur = true;
    },
    openDialogChangeValue(item, typeDate, keyDate) {
      this.itemTable = item;
      this.typeDate = typeDate;
      this.keyDate = keyDate;

      this.dialogChangeValue = true;
    },
    changeDate(event) {
      this.dialogDate = false;
      this.itemTable[this.keyDate] = event;
    },
    changeValue(event) {
      this.dialogChangeValue = false;
      this.itemTable[this.keyDate] = event;
    },
    changeValueAssureurAndScore(event) {
      this.itemTable.score = event.score;
      this.itemTable.assureur = event.assureur;
      this.dialogAssureur = false;
    },

    // add Assurance
    async addAssurance(event) {
      try {
        const token = this.$store.getters.getToken || null;
        const response = await api.post('createAssureur/', {
          ...event,
          "token": token
        });
        if (response.status == 200) {
          await this.getData();
        }
      } catch (error) {
        console.log(error)
      }
      this.closeModalAddFournisseurAssurance();
    },
    openModalAddFournisseurAssurance() {
      this.dialogAddFournisseurAssurance = true
    },
    closeModalAddFournisseurAssurance() {
      this.dialogAddFournisseurAssurance = false
    }
  },
};
</script>
<style scoped>
.td-table {
  width: 12vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.v-tooltip__content {
  font-size: 14px !important;
  opacity: 1 !important;
  padding: 5px !important;
  height: 70px !important;
  width: auto !important;
  text-overflow: ellipsis !important;

}


</style>