<template>
  <div class="d-flex justify-content-center align-items-center" style="width:100%;height:200px">
      <v-card elevation='0' class="d-flex flex-column justify-content-center align-items-center" style="width:100%;height:100%">
              <v-progress-circular indeterminate color='primary' size="100"></v-progress-circular>
      </v-card>
  </div>
</template>
<script>
export default {
  name:'ProgressCircular'
}
</script>