<template>
  <v-dialog v-model="dialogAssureur" persistent width="500">
    <v-card class="d-flex flex-column">
      <v-card-title style="width: 100%" class="ma-0 pa-0">
        <v-toolbar width="100%" height="50" :color="$colors[0]" class="text-white" dark>
          <span>Changement {{ typeDate }} - {{ itemTable.fournisseur }} </span>
          <v-icon style="position: absolute; right: 10px" @click="$emit('closeDialog')"
            >mdi-close</v-icon
          >
        </v-toolbar>
      </v-card-title>
      <div class="pa-4">
        <span style="font-size: 12px; font-weight: 500" :style="{ color: $colors[0] }"
          >Liste Assureurs</span
        >
        <v-select
          placeholder="Liste Assureurs"
          class="mt-1"
          outlined
          dense
          v-model="assureurModel"
          :items="listAssureurs"
        ></v-select>
        <v-form ref="changeAssureur" v-if="!!assureurModel">
          <span style="font-size: 12px; font-weight: 500" :style="{ color: $colors[0] }"
            >Valeur Score</span
          >
          <v-text-field
            class="mt-1"
            outlined
            :rules="[
              $rules.required,
              $rules.isNumber,
              $rules.ruleValue(typeDate, assureurModel, scoreAssureurModel),
            ]"
            dense
            type="number"
            v-model="scoreAssureurModel"
            placeholder="Valeur Score"
          >
          </v-text-field>
          <v-btn
            @click="changeAssureurAndScore()"
            width="100%"
            class="white--text"
            :color="$colors[0]"
            >Valider</v-btn
          >
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DialogAssureur",
  props: ["dialogAssureur", "itemTable", "typeDate", "keyDate"],
  data() {
    return {
      listAssureurs: ["CREDITSAFE", "ELLIPRO"],
      assureurModel: this.itemTable.assureur,
      scoreAssureurModel: this.itemTable.score,
    };
  },
  mounted() {
    console.log(this.typeDate, this.assureurModel, this.scoreAssureurModel);
  },
  methods: {
    changeAssureurAndScore() {
      if (this.$refs.changeAssureur.validate()) {
        this.$emit("changeAssureurAndScore", {
          assureur: this.assureurModel,
          score: this.scoreAssureurModel,
        });
        this.assureurModel = "";
        this.scoreAssureurModel = "";
      }
    },
  },
};
</script>
