<template>
  <div>
    <v-dialog v-model="dialogDate" persistent width="500">
      <v-card class="d-flex flex-column">
        <v-card-title style="width: 100%" class="ma-0 pa-0">
          <v-toolbar width="100%" height="50" :color="$colors[0]" class="text-white" dark>
            <span>Changement {{ typeDate }} - {{ itemTable.fournisseur }} </span>
            <v-icon style="position: absolute; right: 10px" @click="$emit('closeDialog')"
              >mdi-close</v-icon
            >
          </v-toolbar>
        </v-card-title>
        <div>
          <div class="pa-4 pb-0">
            <span
              style="font-size: 12px; font-weight: 500"
              :style="{ color: $colors[0] }"
              >{{
                keyDate == "date_debut_min"
                  ? "Date Debut Min"
                  : keyDate == "date_debut_max"
                  ? "Date debut max"
                  : keyDate == "date_fin_min"
                  ? "Date fin min"
                  : "Date fin max"
              }}</span
            >

            <v-text-field
              class="mt-1"
              outlined
              disabled
              dense
              v-model="itemTable[keyDate]"
            ></v-text-field>
          </div>
          <div class="pa-4 pt-0 d-flex flex-column" style="height: 100%">
            <span style="font-size: 12px; font-weight: 500" :style="{ color: $colors[0] }"
              >Choix format date</span
            >

            <v-select
              style="max-height: 70px"
              class="mt-1"
              outlined
              dense
              placeholder="Choix format date"
              v-model="choiceDateFormat"
              :items="listChoiceDateFormat"
            >
            </v-select>
            <div v-if="!!choiceDateFormat">
              <span
                style="font-size: 12px; font-weight: 500"
                :style="{ color: $colors[0] }"
                >Valeur date</span
              >

              <v-form ref="changeDate">
                <v-text-field
                  class="mt-1"
                  v-if="choiceDateFormat == 'M+'"
                  v-model="MDate"
                  prefix="M+"
                  placeholder="4"
                  :rules="[$rules.isNumber, $rules.required]"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  class="mt-1"
                  v-if="choiceDateFormat == 'NA'"
                  disabled
                  outlined
                  :rules="[$rules.required]"
                  value="NA"
                  dense
                ></v-text-field>
                <v-text-field
                  class="mt-1"
                  v-if="choiceDateFormat == 'Calendrier'"
                  type="date"
                  :rules="[$rules.required, $rules.isDateValid]"
                  v-model="newDate"
                  outlined
                  dense
                ></v-text-field>
              </v-form>
              <v-btn
                width="100%"
                dense
                :color="$colors[0]"
                class="white--text align-self-end mt-8"
                @click="changeDate()"
                >Valider</v-btn
              >
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "DialogDate",
  props: ["dialogDate", "itemTable", "typeDate", "keyDate"],
  data() {
    return {
      listChoiceDateFormat: ["NA", "M+", "Calendrier"],
      choiceDateFormat: "",
      newDate: this.$dayjs(new Date()).format("YYYY-MM-DD"),
      MDate: "",
    };
  },

  methods: {
    changeDate() {
      if (this.$refs.changeDate.validate()) {
        console.log("ici");
        let changeValueDate = "";
        if (this.choiceDateFormat === "NA") {
          changeValueDate = "NA";
        } else if (this.choiceDateFormat === "M+") {
          this.newDate = `M+${this.MDate}`;
          changeValueDate = this.newDate;
        } else {
          changeValueDate = this.newDate;
        }
        console.log(changeValueDate);
        this.choiceDateFormat = "";
        this.newDate = "";
        this.$emit("changeDate", changeValueDate);
      }
    },

    /*changeAssureur() {
      if (this.typeDate === "assureur") {
        if (this.$refs.changeAssureur.validate()) {
          this.itemTable.score = this.scoreAssureurModel;
          this.itemTable.assureur = this.assureurModel;
          this.dialogDate = false;
        }
      } else if (this.typeDate === "score") {
        if (this.$refs.changeScore.validate()) {
          this.itemTable.score = this.scoreAssureurModel;
          this.dialogDate = false;
        }
      }
    },*/
  },
};
</script>
