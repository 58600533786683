import axios from 'axios';

export const baseURL = process.env.VUE_APP_URL_API_CLIENT;

// Create an Axios instance with a base URL.
const api = axios.create({
  baseURL: baseURL,
});

// Add a request interceptor
api.interceptors.request.use(function (config) {
  // Do something before request is sent
  const token = localStorage.getItem('token'); // Get the token
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
api.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response.status == 400 || error.response.status == 404) {
    alert(error.response.data.message);
  } else if (error.response.status == 401) {
    alert('Non autorisé');
  } else if (error.response.status == 500) {
    alert('Internal server error');
  }
  return Promise.reject(error);
});

export default api;