<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import "./css/base.css";
@import "./css/global.css";
@font-face {
  font-family: "Poppins-Regular";
  src: url("assets/fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("assets/fonts/Poppins-Bold.ttf") format("truetype");
}
.Poppins-Bold {
  font-family: "Poppins-Bold";
}
.Poppins-Regular {
  font-family: "Poppins-Regular";
}
* {
  font-family: "Poppins-Regular";
}
</style>
