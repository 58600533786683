<template>
  <div class="fournisseur-turple ma-4">
    <CustomDatatable
      ref="customDatatable"
      :columns="columns"
      sortKey=""
      sortOrders=""
      :isPaginated="true"
      :isLoading="isLoading"
      :haveNoData="liste?.length == 0 && isLoading == false"
      :paginationObjectProps = "pagination"
      @sort=""
      @refreshData="getData"
      @search="searchData"
    >
      <template v-slot:body>
        <tbody class="text-center">
          <tr v-for="(item, index) in liste">
            <td>
              <p>
                {{ item.GRT }}
              </p>
            </td>
            <td>
              <input
                v-if="lineEditedIndex == index"
                v-model="editedLine.TCS"
                type="text"
                class="input"
              />
              <p v-else>
                {{ Number(item.TCS).toLocaleString("fr-FR") }}
              </p>
            </td>
            <td>
              <input
                v-if="lineEditedIndex == index"
                v-model="editedLine.TCR"
                type="text"
                class="input"
              />
              <p v-else>
                {{ Number(item.TCR).toLocaleString("fr-FR") }}
              </p>
            </td>
            <td>
              <input
                v-if="lineEditedIndex == index"
                v-model="editedLine.TCLPITD"
                type="text"
                class="input"
              />
              <p v-else>
                {{ Number(item.TCLPITD).toLocaleString("fr-FR") }}
              </p>
            </td>
            <td>
              <input
                v-if="lineEditedIndex == index"
                v-model="editedLine.TCLtran"
                type="text"
                class="input"
              />
              <p v-else>
                {{ Number(item.TCLtran).toLocaleString("fr-FR") }}
              </p>
            </td>
            <td>
              <input
                v-if="lineEditedIndex == index"
                v-model="editedLine.termStockage"
                type="text"
                class="input"
              />
              <p v-else>
                {{ Number(item.termStockage).toLocaleString("fr-FR") }}
              </p>
            </td>
            <td>
              <input
                v-if="lineEditedIndex == index"
                v-model="editedLine.TFL"
                type="text"
                class="input"
              />
              <p v-else>
                {{ Number(item.TFL).toLocaleString("fr-FR") }}
              </p>
            </td>

            <td>
              <div class="d-flex flex-row justify-center" v-if="lineEditedIndex == index">
                <v-btn @click="closeLineEdit" color="gray" outlined>
                  <v-icon dense>mdi-pencil-off-outline</v-icon>
                </v-btn>
                <v-btn class="ml-4" @click="changeItem(item)" outlined color="green">
                  <v-icon dense>mdi-check-all</v-icon>
                </v-btn>
              </div>

              <v-btn
                v-else
                @click="showLineEdit(index, item)"
                outlined
                :color="$colors[0]"
              >
                <v-icon dense>mdi-pencil-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </CustomDatatable>
  </div>
</template>
<script>
import CustomDatatable from "@/components/datatable/CustomDatatable.vue";
import api from "@/services/api";

export default {
  name: "FormulaireTaxe",
  components: {
    CustomDatatable,
  },
  data() {
    return {
      lineEditedIndex: null,
      editedLine: {},
      liste: [],
      columns: [
        { width: "12vw", label: "GRT", name: "GRT", sortable: false },
        {
          width: "12vw",
          label: "TCS",
          name: "TCS",
          sortable: false,
        },
        {
          width: "12vw",
          label: "TCR",
          name: "TCR",
          sortable: false,
        },
        {
          width: "12vw",
          label: "TCLPITD",
          name: "TCLPITD",
          sortable: false,
        },
        {
          width: "12vw",
          label: "TCLtran",
          name: "TCLtran",
          sortable: false,
        },
        {
          width: "12vw",
          label: "termStockage",
          name: "termStockage",
          sortable: false,
        },
        {
          width: "12vw",
          label: "TFL",
          name: "TFL",
          sortable: false,
        },
        {
          width: "12vw",
          label: "Actions",
          name: "composante_annuelle_gestion",
          sortable: false,
        },
      ],

      tableData: {
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
        filterItems: null,
        filterType: "all",
      },
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 1,
        lastPage: 1,
        currentPage: 1,
        total: 0,
        lastPageUrl: null,
        nextPageUrl: null,
        prevPageUrl: null,
        from: 0,
        to: 0,
      },
    };
  },
  created() {
    this.getData();
  },


  methods: {
    showLineEdit(index, item) {
      this.editedLine = { ...item };
      this.lineEditedIndex = index;
    },
    changeItem(item) {
      Object.entries(this.editedLine).forEach((element) => {
        item[element[0]] = element[1];
      });
      this.lineEditedIndex = null;
      // Update item in database
      var id = item.id;
      var GRT = item.GRT;
      var TCS = item.TCS;
      var TCR = item.TCR;
      var TCLPITD = item.TCLPITD;
      var TCLtran = item.TCLtran;
      var TFL = item.TFL;
      var termStockage = item.termStockage;
      const token = this.$store.getters.getToken || null;
      const formData = new FormData();
      formData.append('id', id);
      formData.append('GRT', GRT);
      formData.append('TCS', TCS);
      formData.append('TCR', TCR);
      formData.append('TCLPITD', TCLPITD);
      formData.append('TCLtran', TCLtran);
      formData.append('TFL', TFL);
      formData.append('termStockage', termStockage);
      formData.append('token', token);
      api.post('updateATRDATRTGAZ/', formData).then((response) => {
       if (response.data.result == true) {
          alert('File uploaded successfully');   
         

        }else{
          alert('File uploaded failed');        
        }
      });




      //GRT,TCLPITD,TCLtran,TCR,TCS,TFL,id,termStockage
    },
    closeLineEdit() {
      this.lineEditedIndex = null;
    },
    searchData(searchWord) {
      this.tableData.search = searchWord;
      this.getData();
    },
    async getData(pageNumber = 1) {
      this.isLoading = true;
      try {
        const token = this.$store.getters.getToken || null;
        const response = await api.post('getAllATRDATRTGAZDatatable/', {
          dataTable: this.tableData,
          "pageNumber": pageNumber,
          "token": token
        });
        if (response.data.result == true) {

          let data = response.data.data;
          console.log(data)
          this.liste = data.data.data;
          if (this.tableData.draw == data.draw) {
            this.configPagination(data.data);
          }
        }
      } catch (error) {
        console.log(error)
      }
      this.isLoading = false;
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
  },
};
</script>

<style scoped></style>
