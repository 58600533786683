<template>
  <div class="fournisseur-turple ma-4">
    <v-dialog v-model="dialogAddFichier" width="700">
      <v-card>
        <v-card-title style="width: 100%" class="ma-0 pa-0">
          <v-toolbar width="100%" height="50" :color="$colors[0]" class="text-white" dark>
            <span>Ajouter un Fichier </span>
            <v-icon style="position: absolute; right: 10px" @click="closeDialog()"
              >mdi-close</v-icon
            >
          </v-toolbar>
        </v-card-title>
        <v-form class="pa-4" ref="formAddFichier">
          <div>
            <span style="font-size: 12px; font-weight: 500" :style="{ color: $colors[0] }"
              >Liste de fournisseurs</span
            >
            <v-select
              class="mt-2"
              placeholder="Liste de fournisseurs"
              outlined
              dense
              :rules="[$rules.required]"
              v-model="fournisseursModel"
              :items="listFournisseurs"
            >
            </v-select>
          </div>
          <div>
            <span style="font-size: 12px; font-weight: 500" :style="{ color: $colors[0] }"
              >Energie</span
            >
            <v-select
              class="mt-2"
              placeholder="Type Energie"
              outlined
              dense
              :rules="[$rules.required]"
              v-model="energieModel"
              :items="listeEnergie"
            >
            </v-select>
          </div>
          <div>
            <span style="font-size: 12px; font-weight: 500" :style="{ color: $colors[0] }"
              >Fichiers</span
            >
            <v-select
              multiple
              class="mt-2"
              placeholder="Fichiers"
              outlined
              dense
              :rules="[$rules.required]"
              v-model="fichiersModel"
              :items="listeFichier"
            >
            </v-select>
          </div>
          <div class="d-flex flex-row justify-end">
            <v-btn class="white--text" :color="$colors[0]" @click="createFichier()"
              >Créer Fichier</v-btn
            >
          </div>
          <!--<div>
            <span style="font-size: 12px; font-weight: 500" :style="{ color: $colors[0] }"
              >Fichiers</span
            >
            <v-text-field
              class="mt-2"
              placeholder="Fichiers"
              outlined
              dense
              :rules="[$rules.required]"
              v-model="fichiersModel"
            >
            </v-text-field>
          </div>-->
        </v-form>
      </v-card>
    </v-dialog>
    <div class="ma-4 ml-0">
      <v-btn :color="$colors[0]" class="white--text" @click="dialogAddFichier = true">
        Ajouter
      </v-btn>
    </div>
    <CustomDatatable
      ref="customDatatable"
      :columns="columns"
      sortKey=""
      sortOrders=""
      :isPaginated="true"
      :isLoading="isLoading"
      :haveNoData="liste?.length == 0 && isLoading == false"
      :paginationObjectProps = "pagination"
      @sort=""
      @refreshData="getData"
      @search="searchData"
    >
      <template v-slot:body>
        <tbody class="text-center">
          <tr v-for="(item, index) in liste">
            <td>
              <input
                v-if="lineEditedIndex == index"
                v-model="editedLine.nom"
                type="text"
                class="input"
              />
              <p v-else>
                {{ item.nom }}
              </p>
            </td>
            <td>
              <input
                v-if="lineEditedIndex == index"
                v-model="editedLine.files"
                type="text"
                class="input"
              />
              <p v-else>
                {{ item.files }}
              </p>
            </td>
            <td>
              <input
                v-if="lineEditedIndex == index"
                v-model="editedLine.type"
                type="text"
                class="input"
              />
              <p v-else>
                {{ item.type }}
              </p>
            </td>
            <td>
              <div class="d-flex flex-row justify-center" v-if="lineEditedIndex == index">
                <v-btn @click="closeLineEdit" color="gray" outlined>
                  <v-icon dense>mdi-pencil-off-outline</v-icon>
                </v-btn>
                <v-btn class="ml-4" @click="changeItem(item)" outlined color="green">
                  <v-icon dense>mdi-check-all</v-icon>
                </v-btn>
              </div>

              <v-btn
                v-else
                @click="showLineEdit(index, item)"
                outlined
                :color="$colors[0]"
              >
                <v-icon dense>mdi-pencil-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </CustomDatatable>
  </div>
</template>
<script>
import CustomDatatable from "@/components/datatable/CustomDatatable.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import api from "@/services/api";

export default {
  name: "FormulaireFichier",
  components: {
    CustomDatatable,
    Breadcrumbs,
  },
  data() {
    return {
      isLoading: false,

      lineEditedIndex: null,
      dialogAddFichier: false,
      editedLine: {},
      fournisseursModel: "",
      listFournisseurs: ["PRIMEO", "ENOVOS", "EDS"],
      energieModel: "",
      listeEnergie: ["ELEC", "GAZ"],
      fichiersModel: [],
      listeFichier: ["contrat", "RIB", "SEPA", "CGV"],
      liste: [],
      columns: [
        { 
         width: "12vw",
         label: "Fournisseur", 
         name: "fournisseur", 
         sortable: false 
        },
        {
          width: "12vw",
          label: "Fichiers",
          name: "composante_annuelle_soutirage",
          sortable: false,
        },
        {
          width: "12vw",
          label: "Type Contrat",
          name: "composante_annuelle_gestion",
          sortable: false,
        },
        {
          width: "12vw",
          label: "Actions",
          name: "composante_annuelle_gestion",
          sortable: false,
        },
      ],
      tableData: {
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
        filterItems: null,
        filterType: "all",
      },
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 1,
        lastPage: 1,
        currentPage: 1,
        total: 0,
        lastPageUrl: null,
        nextPageUrl: null,
        prevPageUrl: null,
        from: 0,
        to: 0,
      },
    };
  },
  created() {
    this.getData();
  },

  methods: {
    showLineEdit(index, item) {
      this.editedLine = { ...item };
      this.lineEditedIndex = index;
    },
    changeItem(item) {
      Object.entries(this.editedLine).forEach((element) => {
        item[element[0]] = element[1];

      });
      this.lineEditedIndex = null;
      // update the item in the database
      var nom = item.nom;
      var files = item.files;
      var type = item.type;
      var id = item.id;
      console.log(nom, files, type, id);
      const token = this.$store.getters.getToken || null;
      const formData = new FormData();
      formData.append("nom", nom);
      formData.append("files", files);
      formData.append("type", type);
      formData.append("id", id);
      formData.append("token", token);
      api.post("updateDocumentPossibleVentePro/", formData).then((response) => {
        if (response.data.result == true) {
          this.getData();
        }else{
          alert('error')
        }
      });
    },
    closeLineEdit() {
      this.lineEditedIndex = null;
    },
    closeDialog() {
      this.dialogAddFichier = false;
    },
    async createFichier() {
      let stringFichier = "";
      for (let i = 0; i < this.fichiersModel.length; i++) {
        stringFichier += this.fichiersModel[i];

        // Ajouter une virgule si ce n'est pas le dernier élément
        if (i < this.fichiersModel.length - 1) {
          stringFichier += ",";
        }
      }
      let objectCreate = {
        nom: this.fournisseursModel,
        files: stringFichier,
        type: this.energieModel,
      };
      this.liste.push(objectCreate);
      try {
        const token = this.$store.getters.getToken || null;
        const response = await api.post("createDocumentPossibleVentePro/", {
          "token": token,
          "nom": this.fournisseursModel,
          "files": stringFichier,
          "type": this.energieModel,
        });
        if (response.data.result == true) {
          this.getData();
          // clear the form
          this.clear();
        }
      } catch (error) {
        console.log(error);

      }
      this.dialogAddFichier = false;
      },

    searchData(searchWord) {
      this.tableData.search = searchWord;
      this.getData();
    },
    async getData(pageNumber = 1) {
      this.isLoading = true;
      try {
        const token = this.$store.getters.getToken || null;
        const response = await api.post('getAlldocumentPossibleVenteProDatatable/', {
          dataTable: this.tableData,
          "pageNumber": pageNumber,
          "token": token
        });
        if (response.data.result == true) {

          let data = response.data.data;
          console.log(data)
          this.liste = data.data.data;
          if (this.tableData.draw == data.draw) {
            this.configPagination(data.data);
          }
        }
      } catch (error) {
        console.log(error)
      }
      this.isLoading = false;
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    clear() {
      this.fournisseursModel = "";
      this.energieModel = "";
      this.fichiersModel = [];
    },

  },
};
</script>

<style scoped></style>
