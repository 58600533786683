<template>
  <div>
    <!-- search -->
    <div v-if="searchVisible" class="d-flex justify-content-start align-items-center my-4">
      <input class="form-control search-input" type="text" v-model="search" placeholder="Rechercher..."
        @input="$emit('search', search)" style="width: 300px" />
    </div>

    <div style="width: 100%; overflow-x: auto; background-color: #fff;">
      <table class="table table-striped table-bordered w-100">
        <!-- table head -->
        <thead class="">
          <tr>
            <th v-for="(column, index) in columns" 
              :key="index" 
              :class="sortKey == column.name
              ? sortOrders[column.name] > 0
                ? 'sorting_asc'
                : 'sorting_desc'
              : 'sorting'" 
              class="tableHead Poppins" 
              :style="'width:' + column.width + '; min-width: fit-content; text-align: center;text-wrap: nowrap;height: 50px; cursor:pointer;'" 
              @click="onColumnSortClicked(column.sortable, column.name)"
            >
              {{ column.label }}
            </th>
          </tr>
        </thead>

        <!-- table body -->
        <slot  v-if="!haveNoData && !isLoading" name="body"></slot>
      </table>
      <NoDataTable v-if="haveNoData && !isLoading" />
      <ProgressCircular v-else-if="isLoading" />
    </div>

    <!-- pagination -->
    <Pagination 
      v-if="paginationVisible" 
      :pagination="pagination" 
      @selectNumberOfRecords="$emit('rechercheData', $event)"
      @first="refreshDataEmit(pagination.firstPage)" 
      @last="refreshDataEmit(pagination.lastPage)"
      @prev="refreshDataEmit(pagination.prevPageUrl)" 
      @current="refreshDataEmit(pagination.currentPage)"
      @next="refreshDataEmit(pagination.nextPageUrl)" 
    />
  </div>
</template>

<script>
import Pagination from "./Pagination.vue";
import NoDataTable from "./NoDataTable.vue";
import ProgressCircular from "../ProgressCircular.vue";

export default {
  name: "CustomDatatable",
  props: [
    "columns",
    "sortKey",
    "sortOrders",
    "isSearchable",
    "isPaginated",
    "haveNoData",
    "isLoading",
    "refreshData",
    "paginationObjectProps",
  ],
  components: {
    Pagination,
    NoDataTable,
    ProgressCircular
  },
  data() {
    return {
      search: "",
      searchVisible: true,
      paginationVisible: false,
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 1,
        lastPage: 1,
        currentPage: 1,
        total: 0,
        lastPageUrl: null,
        nextPageUrl: null,
        prevPageUrl: null,
        from: 0,
        to: 0,
      },
    };
  },
  watch: {
    paginationObjectProps: function () {
      this.pagination = this.paginationObjectProps;
    },
  },
  mounted() {
    if (this.isSearchable == false) {
      this.searchVisible = false;
    }
    if (this.isPaginated) {
      this.paginationVisible = true;
    }
    if (this.paginationObjectProps) {
      this.pagination = this.paginationObjectProps;
    }
  },
  methods: {
    refreshDataEmit(pagination) {
      this.$emit("refreshData", pagination);
    },

    onColumnSortClicked(sortable, key) {
      if (sortable) {
        this.$emit("sort", key);
      }
    },
  },
};
</script>

<style scoped>
@import "./css/styles.css";
</style>
