<template>
  <div>
    <!-- app-bar -->
    <v-app-bar flat app class="app-bar-wrapper">
      <div class="app-bar-nav">
        <v-app-bar-nav-icon class="drawer-toggle-icon" @click="drawer = !drawer">
        </v-app-bar-nav-icon>
        <div class="d-flex justify-content-between align-items-center">
          <!-- user info button -->
          <div class="dropdown-user-info">
            <div class="dropdown-user-info-toggler">
              <div style="display: flex; flex-direction: column">
                <span style="font-size: 12px; font-weight: 600; text-align: right">{{
                  userName
                }}</span>
                <span style="font-size: 12px; font-weight: 400; text-align: right">{{
                  userRole
                }}</span>
              </div>
              <div class="user-info-circle">
                <img class="user-info-circle-image" :src="require(`@/assets/profile.png`)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-app-bar>

    <!-- drawer -->
    <v-navigation-drawer v-model="drawer" flat app class="drawer-wrapper">
      <router-link to="/" class="logo">
        <v-img
          alt="Dashboard Logo"
          class="shrink ma-2"
          contain
          src="../../assets/gemc.png"
          transition="scale-transition"
          width="100"
          height="100"
        />
      </router-link>
      <v-list nav dense class="pa-0 mt-5">
        <v-list-item-group class="px-2" active-class="drawer-item-active">
          <!-- side bar item without sub routes -->
          <v-list-item
            class="drawer-item"
            active-class="drawer-item-active"
            v-for="(route, index) in filteredRoutes"
            v-if="route.children[0].name != 'login'"
            :key="index"
          >
            <router-link :to="`${route.path}`" class="d-flex flex-row drawer-item-link">
              <v-list-item-icon class="pl-2">
                <v-icon size="24" class="drawer-item-icon">{{
                  route.children[0].meta.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="drawer-item-text">{{
                route?.children[0].name
              }}</v-list-item-title>
            </router-link>
          </v-list-item>

          <!-- logout -->
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="mt-10" style="width: 100%; background-color: #01579b">
          <div class="d-flex justify-content-center" app>
            <v-btn
              plain
              :color="$colors[0]"
              class="text-white"
              elevation="0"
              depressed
              @click="logout"
            >
              <span class="Poppins-Bold">Déconnexion</span>
              <v-icon class="mx-2">mdi-logout-variant</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: "Drawer",
  components: {},
  data() {
    return {
      drawer: true,
    };
  },
  created() {
    let user = JSON.parse(localStorage.getItem("user"));
    console.log(user.nom);
    this.userName = `${user.nom} ${user.prenom}`;
    this.userRole = user.role;
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    filteredRoutes() {
      const filtered = this.$router.options.routes.filter((route) => {
        console.log(route);
        if (route?.children[0].meta?.roles?.length > 0 || route?.children[0].meta?.hidden ) {
          return (
            route?.children[0].meta?.roles?.includes("admin") &&
            !route?.children[0].meta?.hidden
          );
        } else {
          return true;
        }
      });
      return filtered;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    navigateTo(routePath) {
      const currentRoutePath = this.$router.currentRoute.path;
      if (currentRoutePath !== routePath) {
        this.$router.push(routePath);
      }
    },
  },
};
</script>
<style scoped>
.logo {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 150px;
  padding: 15px;
}
.app-bar-wrapper {
  background-color: #fff !important;
  border-bottom: 1px solid #ddd !important;
}
.app-bar-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
/* drawer */
.drawer-wrapper {
  background-color: var(--drawer-bg-color);
}
.drawer-toggle-icon {
  color: var(--drawer-icon-color) !important;
}
/*drawer item*/
.drawer-item,
.drawer-item-link {
  width: 100%;
  text-decoration: none;
  color: var(--drawer-item-text-color);
}
.drawer-item {
  padding: 6px 4px;
}
.drawer-item:hover {
  border-radius: 4px;
  color: var(--drawer-item-hover-text-color);
  background: var(--drawer-item-hover-bg-color);
}
.drawer-item-icon {
  font-size: 24px;
  color: var(--drawer-item-icon-color);
}
.drawer-item-text {
  font-size: 14px;
  font-weight: 700;
  color: var(--drawer-item-text-color);
  text-transform: capitalize;
}

.drawer-item:hover .drawer-item-text,
.drawer-item:hover .drawer-item-icon{
  color: var(--drawer-item-hover-text-color);
}

.drawer-item-active {
  background: var(--drawer-item-hover-bg-color);
}
.drawer-item-active .drawer-item-text,
.drawer-item-active .drawer-item-icon {
  color: var(--drawer-item-active-text-color);
}
/****************************** */
/* user info dropdown */
/****************************** */
.dropdown-user-info {
  display: inline-block;
  padding: 8px;
}

.dropdown-user-info-toggler {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: #000;
  user-select: none;
}

.user-info-circle {
  width: 40px;
  height: 40px;
  background-color: #bbcfce;
  border-radius: 50%;
  margin-left: 12px;
  overflow: hidden;
  position: relative;
}
.user-info-circle-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}
.devider {
  width: 100%;
  height: 1px;
  background-color: #e2e2e2;
}
</style>
